import { config } from "~/data/config";
import {
  SquidOnboarding,
  VerificationPlatform,
  VerifySquidOnboardingResponse,
} from "~/server/types/auth";
import {
  CoinsSummaryResponse,
  OrderDetails,
  PaymentOrderDetails,
  ProcurementOrderDetailResponse,
  ProcurementOrderInitiateProps,
  ProcurementOrderInitiateResponse,
  ProcurementProformaInvoiceGenerationProps,
  ProcurementProformaInvoiceGenerationResponse,
  ProcurementSimulateProps,
  ProcurementSimulateResponse,
  RedemptionParams,
  RedemptionResponse,
} from "~/server/types/order";
import { Cookie, Header, QueryParam } from "~/types";
import { getCookie } from "~/utils/client_cookie";
import { APIError } from "~/utils/fetch";
import {
  BrandHowToUseStepsResponse,
  GeneratePartnerOTPResponse,
  GeneratePartnerOTPBody,
  GiftCardBalance,
  SimulatedTransactionResponse,
  SimulateTransactionRequest,
  VerifyPartnerOTPResponse,
  VoucherProduct,
  BrandExtraMetadata,
  VerifyCodeResponse,
  VerifyCodeBody,
  ClientMinimalConfig,
} from "../types/brand";
import { PaginationRequest, SearchResults } from "../types/search";
import { hubbleFetch } from "./base";
import { getOS } from "~/utils/platform";
import { defaultHeaders } from "~/data/auth";
import { getModeFromQueryParamsAndCookies, newRequestId } from "~/utils/common";
import { CoinsTransactionsResponse } from "~/data/transaction";
import {
  GetGiftBoxV3Response,
  GiftAuthMethod,
  GiftBoxDeliveryResponse,
  GiftBoxSendOtpResponse as GiftSendOtpResponse,
  GiftDetailsMinimalResponse,
  GiftVoucherDetailsResponse,
  GiftVoucherStatus,
  VerifyOtpResponseV3 as GiftVerifyOtpResponseV3,
} from "../types/gift";
import { RedeemCodeResponse } from "../types/redeem_code";
import {
  getMockGetGiftVoucherDetailsResponse,
  getMockGiftDetailsMinimalResponse,
} from "./mock_response";
import { UserProfile } from "../types/user";
import {
  ChatDetails,
  Credits,
  GiftCardDetails,
  GiftCardOccasion,
  GiftCardSelection,
  InitiatePayment,
} from "../types/pluto";

function getCompleteHeaders(
  headers?: Record<string, string>
): Record<string, string> {
  let completeHeaders: Record<string, string> = {
    ...headers,
    [Header.ContentType]: "application/json",
    [Header.AppVersion]: "10000",
    [Header.DeviceInfo]: "WEB",
    [Header.DeviceOs]: "WEB",
    [Header.HostOs]: getOS() ?? "UNKNOWN",
    [Header.RequestId]: newRequestId(),
  };

  let sid =
    getCookie(Cookie.SessionId) ||
    defaultHeaders()[Header.SessionId] ||
    localStorage.getItem("sessionId");
  if (sid) {
    completeHeaders = {
      [Header.SessionId]: sid,
      ...completeHeaders,
    };
  }

  const searchParams = new URLSearchParams(window.location.search);
  const clientId = searchParams.get(QueryParam.ClientId);
  const clientSecret = searchParams.get(QueryParam.ClientSecret);
  if (clientId) {
    completeHeaders = {
      ...completeHeaders,
      [Header.ClientId]: clientId as string,
      [Header.ClientSecret]: clientSecret as string,
    };
  }

  return completeHeaders;
}

async function hubbleFetchClient<T>(
  url: string,
  method: string,
  headers?: Record<string, string>,
  body?: any,
  responseTypeNonJson?: boolean
): Promise<T> {
  const completeHeaders = getCompleteHeaders(headers);

  try {
    const response = await hubbleFetch(url, method, completeHeaders, body);

    const mode = getModeFromQueryParamsAndCookies();

    if (!response.ok) {
      if (response.status === 401) {
        if (window.location.href.includes("gift-box")) {
          throw new APIError("Session expired", 401);
        } else if (mode == "sdk") {
          console.log(`sdk session expired. Throwing error`);
          throw new Error("Your session is expired");
        } else if (mode === "rnr" || mode === "squid") {
          if (url.includes("partner/otp/verify")) {
            throw new APIError("Otp is invalid", 401);
          } else {
            console.log(`401 - redirecting to /login`);
            window.location.href = `/login`;
          }
        } else {
          console.log(`401 - logging out`);
          window.location.href = `/logout`;
        }
      } else if (response.status === 429) {
        throw new APIError(
          "Limit is exceeded. Please try after sometime.",
          429
        );
      } else {
        try {
          const errorResponse = await response.json();
          throw new APIError(
            errorResponse.subErrors
              ? errorResponse.subErrors.length > 0
                ? errorResponse.subErrors[0].message
                : errorResponse.message
              : errorResponse.message,
            response.status
          );
        } catch (e) {
          // this is to catch response.json() parse failure
          // If there is already an API error thrown, we will simply rethrow it
          if (e instanceof APIError) throw e;

          throw new APIError("Unknown error occurred. Please try again.", 400);
        }
      }
    }
    if (responseTypeNonJson) {
      return response as T;
    }

    if (response.status !== 204) {
      const responseJson = await response.json();
      return responseJson as T;
    }

    return null as T;
  } catch (e) {
    if (e instanceof APIError) {
      console.trace(
        `client-fetch - API error ${url}, reqId ${completeHeaders[Header.RequestId]}, error ${e.message} code ${e.code}`
      );
    } else {
      console.trace(
        `client-fetch - got exception while fetching ${url}, reqId ${completeHeaders[Header.RequestId]}`,
        e
      );
    }
    throw e;
  }
}

async function hubbleGetClient<T>(
  url: string,
  headers?: Record<string, string>,
  responseTypeNonJson?: boolean
): Promise<T> {
  return hubbleFetchClient<T>(url, "GET", headers, null, responseTypeNonJson);
}

async function hubblePostClient<T>(
  url: string,
  body: any,
  headers?: Record<string, string>,
  responseTypeNonJson?: boolean
): Promise<T> {
  return hubbleFetchClient<T>(url, "POST", headers, body, responseTypeNonJson);
}

async function hubblePatchClient<T>(
  url: string,
  body: any,
  headers?: Record<string, string>
): Promise<T> {
  return hubbleFetchClient<T>(url, "PATCH", headers, body, true);
}

async function hubblePutClient<T>(
  url: string,
  body: any,
  headers?: Record<string, string>
): Promise<T> {
  console.log(url, "PUT", headers, body);
  return hubbleFetchClient<T>(url, "PUT", headers, body, true);
}

export async function simulate(
  request: SimulateTransactionRequest
): Promise<SimulatedTransactionResponse> {
  const url = `${config.baseUrl}/v3/redemptions/simulate`;
  return await hubblePostClient<SimulatedTransactionResponse>(url, request);
}

export async function getPaymentOrderDetails(
  orderId: string,
  headers?: Record<string, string>
): Promise<PaymentOrderDetails> {
  const path = `v1/orders/${orderId}`;
  return hubbleGetClient(`${config.baseUrl}/${path}`, headers);
}

export async function getStoreOrderDetails(
  orderId: string,
  headers?: Record<string, string>
): Promise<OrderDetails> {
  const path = `v1/store/orders/${orderId}`;
  return hubbleGetClient(`${config.baseUrl}/${path}`, headers);
}

export async function initiateRedemption(
  params: RedemptionParams,
  headers?: Record<string, string>
): Promise<RedemptionResponse> {
  const path = "v2/redemptions/provider/pg/initiate";
  return hubblePostClient(`${config.baseUrl}/${path}`, params, headers);
}

export async function verifyUser(
  visitorId: string,
  platform: VerificationPlatform
) {
  const path = "v1/user/verify";
  return hubblePostClient<void>(`${config.baseUrl}/${path}`, {
    id: visitorId,
    platform: platform,
  });
}

export async function getProductSearch(
  query: string,
  pagination?: PaginationRequest,
  headers?: Record<string, string>
): Promise<SearchResults> {
  const path = "v3/store/products/search";
  const queryString = pagination
    ? `q=${query}&limit=${pagination.limit}&pageNo=${pagination.pageNo}`
    : `q=${query}`;
  return hubbleGetClient(`${config.baseUrl}/${path}?${queryString}`, headers);
}

export async function getBrandHowToUseSteps(
  brandId: string
): Promise<BrandHowToUseStepsResponse> {
  const path = `v1/store/products/voucher-product/${brandId}/how-to-redeem-details`;
  return hubbleGetClient(`${config.baseUrl}/${path}`);
}

export async function toggleVoucherStatus(voucherId: string): Promise<void> {
  const path = `v1/store/voucher/${voucherId}/toggle-status`;
  return hubblePostClient(`${config.baseUrl}/${path}`, null);
}

export async function getGiftCardBalance(
  token: string,
  cardNumber: string,
  cardPin: string
): Promise<GiftCardBalance> {
  const queryString = `cardNumber=${cardNumber}&cardPin=${cardPin}&token=${token}`;

  const url = `${config.baseUrl}/public/utils/card-balance?${queryString}`;
  return hubbleGetClient(url);
}

export async function getBrand(
  brandIdOrBrandKey: string
): Promise<VoucherProduct> {
  const url = `${config.baseUrl}/v1/store/products/voucher-product/${brandIdOrBrandKey}`;
  return await hubbleGetClient<VoucherProduct>(url);
}

export async function verifyPartnerCode(
  headers: {
    clientId: string;
    clientSecret: string;
  },
  body: VerifyCodeBody
): Promise<VerifyCodeResponse> {
  const { clientId, clientSecret } = headers;
  const { code } = body;

  const url = `${config.baseUrl}/v1/auth/partner/code/verify`;
  return await hubblePostClient<VerifyCodeResponse>(
    url,
    {
      code,
    },
    {
      [Header.ClientId]: clientId,
      [Header.ClientSecret]: clientSecret,
    }
  );
}

export async function generatePartnerOTP(
  headers: {
    clientId: string;
    clientSecret: string;
  },
  body: GeneratePartnerOTPBody
): Promise<GeneratePartnerOTPResponse> {
  const { clientId, clientSecret } = headers;
  const { phoneNumber, email, medium } = body;

  const url = `${config.baseUrl}/v1/auth/partner/otp/generate`;
  return await hubblePostClient<GeneratePartnerOTPResponse>(
    url,
    {
      phoneNumber,
      email,
      medium,
    },
    {
      [Header.ClientId]: clientId,
      [Header.ClientSecret]: clientSecret,
    }
  );
}

export async function verifyPartnerOTP(
  headers: {
    clientId: string;
    clientSecret: string;
  },
  body: GeneratePartnerOTPBody
): Promise<VerifyPartnerOTPResponse> {
  const { clientId, clientSecret } = headers;
  const { phoneNumber, email, otp, otpToken } = body;

  const url = `${config.baseUrl}/v1/auth/partner/otp/verify`;
  return await hubblePostClient<VerifyPartnerOTPResponse>(
    url,
    {
      phoneNumber,
      email,
      otp,
      otpToken,
    },
    {
      [Header.ClientId]: clientId,
      [Header.ClientSecret]: clientSecret,
    }
  );
}

export async function getCoinTransactions(
  headers?: Record<string, string>
): Promise<CoinsTransactionsResponse> {
  const path = `v1/coins/transactions`;
  return hubbleGetClient(`${config.baseUrl}/${path}`, headers);
}

export async function logout() {
  const url = new URL(`${config.baseUrl}/v1/auth/logout`);
  return hubblePostClient(url.toString(), {});
}

export async function sendOtpForGiftBox(params: {
  id: string;
  deviceVerificationToken: string;
  identifier?: string;
  authMethod?: GiftAuthMethod | null;
}): Promise<GiftSendOtpResponse> {
  if (params.id.includes("mock")) {
    console.table({
      id: params.id,
      identifier: params.identifier,
      authMethod: params.authMethod,
      endpoint: `/v2/gifting/gift-box/${params.id}/auth/send-otp`,
      deviceVerificationToken: params.deviceVerificationToken,
    });
    await new Promise((resolve) => setTimeout(resolve, 4000));
    return {
      otpToken: "otp-token",
    };
  } else {
    const path = `v2/gifting/gift-box/${params.id}/auth/send-otp`;
    return hubblePostClient(
      `${config.baseUrl}/${path}`,
      {
        identifier: params.identifier,
        authMethod: params.authMethod || "PHONE_OTP",
      },
      {
        [Header.FpDeviceToken]: params.deviceVerificationToken,
      }
    );
  }
}

export async function openGiftBox(params: {
  id: string;
  deviceVerificationToken: string;
  otp?: string;
  otpToken?: string;
}): Promise<GiftVoucherDetailsResponse> {
  if (params.id.includes("mock")) {
    return getMockGetGiftVoucherDetailsResponse(params.id);
  }

  const path = `v2/gifting/gift-box/${params.id}/open`;
  return hubblePostClient(
    `${config.baseUrl}/${path}`,
    {
      otp: params.otp,
      otpToken: params.otpToken,
    },
    {
      [Header.FpDeviceToken]: params.deviceVerificationToken,
    }
  );
}

export async function retryGiftBoxWhatsappDelivery(
  id: string,
  deviceVerificationToken: string
): Promise<GiftBoxDeliveryResponse> {
  if (id.includes("mock")) {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return {
      retryAvailable: false,
    };
  }

  const path = `v2/gifting/gift-box/${id}/retry-delivery`;
  return hubblePostClient(
    `${config.baseUrl}/${path}`,
    {},
    {
      [Header.FpDeviceToken]: deviceVerificationToken,
    }
  );
}

export async function getGiftDetailsMinimalResponse(
  key: string,
  deviceVerificationToken?: string
): Promise<GiftDetailsMinimalResponse> {
  if (key.includes("mock")) {
    console.table({
      key,
      deviceVerificationToken,
      endpoint: `/v2/gifting/gift-box/${key}`,
    });
    return await getMockGiftDetailsMinimalResponse(key);
  }
  const url = `${config.baseUrl}/v2/gifting/gift-box/${key}`;
  return hubbleGetClient<GiftDetailsMinimalResponse>(url, {
    [Header.FpDeviceToken]: deviceVerificationToken ?? "",
  });
}

export async function applyGiftCard(
  cardNumber: string,
  clientId?: string
): Promise<RedeemCodeResponse> {
  const path = `v1/coins/gift-card/apply`;

  const headers: Record<string, string> = {};
  if (clientId) {
    headers[Header.ClientId] = clientId;
  }

  return hubblePostClient(
    `${config.baseUrl}/${path}`,
    { cardNumber: cardNumber },
    headers
  );
}

export async function getUserProfile(): Promise<UserProfile> {
  const url = `${config.baseUrl}/v1/user/profile`;
  return await hubbleGetClient<UserProfile>(url);
}

export async function getCoinsSummary(
  headers?: Record<string, string>
): Promise<CoinsSummaryResponse> {
  const path = "v1/coins/summary";
  return hubbleGetClient(`${config.baseUrl}/${path}`, headers);
}

export async function getGiftBoxV3(
  key: string,
  headers?: Record<string, string>
): Promise<GetGiftBoxV3Response> {
  const path = `v3/gifting/gift/${key}`;
  return hubbleGetClient(`${config.baseUrl}/${path}`, headers);
}

export async function sendGiftOtpV3(params: {
  id: string;
  identifier?: string;
  headers?: Record<string, string>;
}): Promise<GiftSendOtpResponse> {
  const path = `v3/gifting/gift/${params.id}/auth/send-otp`;
  return hubblePostClient(
    `${config.baseUrl}/${path}`,
    {
      identifier: params.identifier,
      authMethod: "PHONE_OTP",
    },
    params.headers
  );
}

export async function verifyGiftOtpV3(params: {
  id: string;
  otp: string;
  otpToken: string;
  headers?: Record<string, string>;
}): Promise<GiftVerifyOtpResponseV3> {
  const path = `v3/gifting/gift/${params.id}/auth/verify-otp`;
  return hubblePostClient(
    `${config.baseUrl}/${path}`,
    {
      otp: params.otp,
      otpToken: params.otpToken,
    },
    params.headers
  );
}

export async function simulateCart(
  payload: ProcurementSimulateProps
): Promise<ProcurementSimulateResponse> {
  const path = "v2/procurement/orders/simulate";
  return hubblePostClient(`${config.baseUrl}/${path}`, payload);
}

export async function procurementOrderInitiate(
  props: ProcurementOrderInitiateProps
): Promise<ProcurementOrderInitiateResponse> {
  const path = "v2/procurement/orders/initiate";
  return hubblePostClient(`${config.baseUrl}/${path}`, props);
}

export async function getProcurementOrderDetail(props: {
  orderId: string;
}): Promise<ProcurementOrderDetailResponse> {
  const path = "v1/procurement/orders/" + props.orderId;
  return hubbleGetClient(`${config.baseUrl}/${path}`);
}

export async function getProcurementWalletBalance(): Promise<{
  balance: number;
}> {
  const path = "v1/procurement/wallet/balance";
  return hubbleGetClient(`${config.baseUrl}/${path}`);
}

export async function downloadProcurementOrder(orderId: string): Promise<any> {
  const path = `v1/procurement/orders/${orderId}/download/excel`;
  const headers: Record<string, string> = {};
  return hubbleGetClient(`${config.baseUrl}/${path}`, headers, true);
}

export async function getBrandExtraMetadata(
  brandIdOrBrandKey: string,
  headers?: Record<string, string>
): Promise<BrandExtraMetadata> {
  const url = `${config.baseUrl}/v1/store/products/voucher-product/${brandIdOrBrandKey}/extra-metadata`;
  return await hubbleGetClient<BrandExtraMetadata>(url, headers);
}

export async function updatePartnerUserProfile(
  body: {
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber: string;
  },
  headers?: Record<string, string>
): Promise<any> {
  const url = `${config.baseUrl}/v1/user/partner/profile`;
  return await hubblePatchClient<any>(url, body, headers);
}

export async function getMinimalClientConfigByPortal(
  host: string
): Promise<ClientMinimalConfig> {
  const url = `${config.baseUrl}/v1/client/config/minimal/by-portal-url/${host}`;
  return await hubbleGetClient<ClientMinimalConfig>(url, undefined, undefined);
}


export async function createConversation(): Promise<ChatDetails> {
  const url = `${config.baseUrl}/v1/pluto/conversation`;
  return await hubblePostClient<ChatDetails>(url, {});
}

export async function sendNewMessage(
  id: string,
  message: string
): Promise<ChatDetails> {
  const url = `${config.baseUrl}/v1/pluto/conversation/${id}/chat`;
  return await hubblePostClient<ChatDetails>(url, { message });
}

export async function getGift(id: string): Promise<GiftCardDetails> {
  const url = `${config.baseUrl}/v1/pluto/conversation/${id}/gift`;
  return await hubbleGetClient<GiftCardDetails>(url);
}

export async function getPlutoCredits(): Promise<Credits> {
  const url = `${config.baseUrl}/v1/pluto/credits`;
  return await hubbleGetClient<Credits>(url);
}

export async function updateGiftOccasion(
  id: string,
  { occasionTitle, recipientName, wishMessage }: GiftCardOccasion
): Promise<{ status: number }> {
  const url = `${config.baseUrl}/v1/pluto/gifts/${id}/occasion`;
  return await hubblePutClient(url, {
    occasionTitle,
    recipientName,
    wishMessage,
  });
}

export async function updateGiftSelection(
  id: string,
  selection: GiftCardSelection
): Promise<{ status: number }> {
  const url = `${config.baseUrl}/v1/pluto/gifts/${id}/selection`;
  return await hubblePutClient(url, selection);
}

export async function updateGiftBudget(
  id: string,
  budget: number
): Promise<{ status: number }> {
  const url = `${config.baseUrl}/v1/pluto/gifts/${id}/budget`;
  return await hubblePutClient(url, { budget });
}

export async function initiatePayment(id: string): Promise<InitiatePayment> {
  const url = `${config.baseUrl}/v1/pluto/gifts/${id}/pay`;
  return await hubblePostClient(url, {});
}

export async function getGiftById(id: string): Promise<GiftCardDetails> {
  const url = `${config.baseUrl}/v1/pluto/gifts/${id}`;
  return await hubbleGetClient(url);
}

export async function getAllConversations(): Promise<ChatDetails[]> {
  const url = `${config.baseUrl}/v1/pluto/conversation`;
  return await hubbleGetClient(url);
}

export async function getShareableGiftLink(
  id: string
): Promise<{ link: string }> {
  const url = `${config.baseUrl}/v1/pluto/gifts/${id}/share`;
  return await hubbleGetClient(url);
}

export async function updateUserProfile(
  body: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  },
  headers?: Record<string, string>
): Promise<any> {
  const url = `${config.baseUrl}/v1/user/profile`;
  return await hubblePatchClient<any>(url, body, headers);
}

  
export async function squidOnboarding(params: {
  emailId: string;
  name: string;
  phoneNumber: string;
  businessName: string;
  businessDetails?: {
    gstNumber?: string;
    address?: {
      address1?: string;
      address2?: string;
      place?: string;
      state?: string;
      pincode?: string;
      country?: string;
    };
    gstCertificateUrl?: string;
  };
}): Promise<SquidOnboarding> {
  const url = `${config.baseUrl}/v1/squid/onboard`;
  return await hubblePostClient<SquidOnboarding>(url, params);
}

export async function verifySquidOnboarding(props: {
  id: string;
  params: {
    emailId: string;
    emailVerificationToken: string;
    emailOtp: string;
    phoneNumber: string;
    phoneVerificationToken: string;
    phoneOtp: string;
  };
}): Promise<VerifySquidOnboardingResponse> {
  const url = `${config.baseUrl}/v1/squid/${props.id}/verify`;
  return await hubblePostClient<VerifySquidOnboardingResponse>(
    url,
    props.params
  );
}

export async function getSignedUrl(params: {
  contentType: string;
}): Promise<{ url: string }> {
  const url = `${config.baseUrl}/v1/upload/signed-url`;
  return await hubblePostClient<{ url: string }>(url, {
    purpose: "BUSINESS_DOCUMENT",
    contentType: params.contentType,
  });
}

export async function getProcurementProfileData(): Promise<any> {
  const path = "v1/squid";
  return hubbleGetClient(`${config.baseUrl}/${path}`);
}

export async function generateProformaInvoice(
  params: ProcurementProformaInvoiceGenerationProps
): Promise<ProcurementProformaInvoiceGenerationResponse> {
  const path = "v2/procurement/proforma-invoice/generate";
  return hubblePostClient(`${config.baseUrl}/${path}`, params);
}
